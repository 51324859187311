<!-- 查看更多 -->
<template>
    <div class="SeeMore">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">更多</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <div class="" style="margin-left: 21px;">
            <button class="btn" type="button" v-for="(item,index) in tabBtn" :key="index" @click="toTab(index)"
                :class="tab==index?'active':''">
                {{item}}
            </button>
        </div>
        <el-divider></el-divider>
        <!-- 单据说明 -->
        <div class="" v-if="tab==0" :key="0">
            <el-form ref="form" :model="form" inline class="form" label-width="120px">
                <el-form-item label="单据说明">
                    <el-input  placeholder="" type="textarea" rows="5"
                        class="textArea-width" disabled>
                    </el-input>
                </el-form-item>
            </el-form>
            
            
            <!-- <el-table :data="billData" border stripe style="width:97.8%;flex: 1;" height="650" >
                <el-table-column type="selection" align="center" width="50">
                </el-table-column>
                <el-table-column prop="" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" label="学生号" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="学生姓名" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="报名日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="交费日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="退(转)学日期" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="学籍类" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="报名上课次数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="实付学费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="退(转)费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="优惠率(%)" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="让利" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="学费标准" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="授课次数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="转入金额" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="学费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="经办人员" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="说明" align="center" width="300">
                </el-table-column>
                <el-table-column prop="" label="标准每课次学费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="实际每课次学费" align="center" width="120">
                </el-table-column>
            </el-table> -->
        </div>
        <!-- 排课表 -->
        <div class="" v-if="tab==1" :key="1">
            <el-table :data="scheduleData" border stripe style="width:97.8%;flex: 1;" height="650" >
                <el-table-column type="selection" align="center" width="50">
                </el-table-column>
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="course_type" sortable label="课类" align="center" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.course_type==1">正常课</span>
                        <span v-else-if="scope.row.course_type==2">补课</span>
                    </template>
                </el-table-column>
                <el-table-column prop="course_status" sortable label="状态" align="center" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.course_status==1">可用</span>
                        <span v-else-if="scope.row.course_status==2">取消</span>
                        <span v-else-if="scope.row.course_status==3">已授课</span>
                    </template>
                </el-table-column>
                <el-table-column prop="abstract" sortable label="排课摘要" align="center" width="120">
                </el-table-column>
                <el-table-column prop="number" sortable label="培训课码" align="center" width="120">
                </el-table-column>
                <el-table-column prop="class_name" sortable label="培训班名称" align="center" width="150">
                </el-table-column>
                <el-table-column prop="course_date" sortable label="上课日期" align="center" width="120">
                </el-table-column>
                <el-table-column prop="course_time" sortable label="上课时间" align="center" width="150">
                </el-table-column>
                <el-table-column prop="course_duration" sortable label="上课时长(hr)" align="center" width="130">
                </el-table-column>
                <el-table-column prop="remark" sortable label="备注说明" align="center" width="300">
                </el-table-column>
            </el-table>
        </div>
        <!-- 签到主表 -->
        <div class="" v-if="tab==2" :key="2">
            <el-table :data="signData" border stripe style="width:97.8%;flex: 1;" height="650" >
                <el-table-column type="selection" align="center" width="50">
                </el-table-column>
                <el-table-column prop="" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" label="审核状态" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="单号" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="班级编号" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="班级名称" align="center" width="300">
                </el-table-column>
                <el-table-column prop="" label="授课老师" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="课次" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="上课日期" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="本班人数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="签到人数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="试学人数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="补课人数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="出勤率(%)" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="工作量-1" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="工作量-2" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="上课教室" align="center" width="120">
                </el-table-column>
            </el-table>
        </div>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" 
             @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total" v-if="tab!=0">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                tabBtn:["单据说明","排课表","签到主表"],
                tab:0,//代表下标
                form:{
                    billData:""
                },
                // billData:[],//单据说明表格数据
                scheduleData:[],//排课表 数据
                signData:[],//签到主表数据
                currentPage: 1,
                currentLimit: 20, //条数
                total: 0,
            }
        },
        created() {
            this.getList()
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            getList(){
                if(this.tab==0){//单据说明
                    this.$request({
                        url:'/api/class_/document_remark',
                        method:'POST',
                        data:{
                            id:this.$route.query.id,
                        }
                    }).then(res=>{
                        if(res.code==1){
                            console.log(res,'单据说明')
                            this.form.billData=res.data.document_remark
                            
                        }
                    })
                }else if(this.tab==1){//排课表
                    this.$request({
                        url:'/api/class_/scheduleList',
                        method:'POST',
                        data:{
                            class_id:this.$route.query.id,
                            page:this.currentPage,
                            limit:this.currentLimit
                        }
                    }).then(res=>{
                        if(res.code==1){
                            console.log(res,'排课表')
                            this.scheduleData=res.data.list
                            this.total=res.data.count
                        }
                    })
                }else if(this.tab==2){//签到主表
                    
                }
            },
            toTab(index) {//赋值下标
                this.tab = index
                this.getList()
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            },
        }
    }
</script>

<style scoped="scoped">
    .SeeMore{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    
    /* tab按钮 */
    .btn {
        display: inline-block;
        border: 1px solid #999999;
        background-color: #FFFFFF;
        padding: 5px 15px;
        margin-right: 20px;
        font-size: 14px;
        border-radius: 3px;
        cursor: pointer;
    }
    
    /* 选中样式 */
    .active {
        background-color: #29726C;
        color: #FFFFFF;
    }
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    .form ::v-deep .el-textarea.is-disabled .el-textarea__inner{
        background-color: #fff;
        color: #333;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 35px;
    }
    /* 分页 */
    .el-pagination {
        margin:20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
